import {
    IonContent,
    IonPage,
    IonImg,
    IonButton,
    IonItem,
    IonList,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonText,
    IonGrid,
    IonIcon,
    IonLoading,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import firebaseApp, { auth, signInWithGoogle, signInWithFB } from '../firebase';
/* Pullstate Store */
import { TownStore } from '../store/TownStore';
import { PermitStore } from '../store/PermitStore';
import PLOfull from '../assets/images/PLO_full.png';
import { logoFacebook, logoGoogle } from 'ionicons/icons';

const Login = (props) => {
    const firestore = firebaseApp.firestore();
    const municipality = props.match.params.id;
    const townDoc = TownStore.useState((s) => s.townDoc);
    const [email, setEmail] = useState(``);
    const [password, setPassword] = useState(``);
    const [error, setError] = useState(``);
    const [showLoading, setShowLoading] = useState(false);
    const [emailPassword, setEmailPassword] = useState(false);

    const signInWithEmailAndPasswordHandler = (event) => {
        setShowLoading(true);
        event.preventDefault();
        setError(``);

        if (email.length < 4) {
            setError(`Name must be at least 4 characters.`);
        }

        if (password.length < 4) {
            setError(`Password must be at least 4 characters.`);
        }

        if (error === ``) {
            auth.signInWithEmailAndPassword(email, password)
                .then(() => {
                    console.log('logged in');
                    // history.push('/', {direction: 'none'});
                })
                .catch((e) => {
                    setError(e.message);
                });
        }
        setShowLoading(false);
    };

    useEffect(() => {
        if (municipality) {
            const getTownDoc = () => {
                // console.log(user)
                firestore
                    .collection('municipality')
                    .doc(municipality)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            const id = doc.id;
                            const terms = data.terms;
                            const agree = data.agree;
                            const riskLevel = data.riskLevel;
                            const burnBan = data.fireban;
                            console.log('data ', data);
                            TownStore.update((s) => {
                                s.townDoc = { id, ...data };
                                s.riskLevel = riskLevel;
                                s.burnBan = burnBan;
                            });
                            PermitStore.update((s) => {
                                s.terms = terms;
                                s.agree = agree;
                            });
                        } else {
                            TownStore.update((s) => {
                                s.townDoc = null;
                            });
                        }
                    })
                    .catch((error) => console.log(error));
            };
            const getPermitTypes = () => {
                // console.log(user)
                firestore
                    .collection('municipality')
                    .doc(municipality)
                    .collection('permitTypes')
                    .get()
                    .then((querySnapshot) => {
                        const types = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();
                            console.log(doc.id, ' => ', doc.data());
                            types.push({ id, ...data });
                        });
                        PermitStore.update((s) => {
                            s.permitTypes = types;
                        });
                    })
                    .catch((error) => console.log(error));
            };
            const getAnnouncements = () => {
                firestore
                    .collection('municipality')
                    .doc(municipality)
                    .collection('announcements')
                    .limit(3)
                    .get()
                    .then((querySnapshot) => {
                        const announcementsList = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();
                            // doc.data() is never undefined for query doc snapshots
                            announcementsList.push({ id, ...data });
                            // console.log(doc.id, " => ", doc.data());
                        });
                        const annoucementsSorted = announcementsList.sort((a, b) =>
                            a.dateCreated > b.dateCreated
                                ? -1
                                : b.dateCreated > a.dateCreated
                                ? 1
                                : 0
                        );
                        TownStore.update((s) => {
                            s.announcements = annoucementsSorted;
                        });
                    });
            };
            getTownDoc();
            getPermitTypes();
            getAnnouncements();
        }
    }, [municipality, firestore]);

    useEffect(() => {
        console.log(townDoc);
    }, [townDoc, props]);
    return (
        <IonPage mode='ios'>
            <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} />
            <IonContent>
                <IonGrid className='contentArea ion-padding-horizontal'>
                    <IonRow>
                        <IonCol>
                            <div style={{ height: 50 }} />
                            <IonImg className='ion-padding' src={PLOfull} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <h2 className='ion-text-center headingText'>
                                One-Click Registration/Login
                            </h2>
                        </IonCol>
                    </IonRow>
                    {!emailPassword ? (
                        <>
                            <IonRow>
                                <IonCol>
                                    <IonButton
                                        // className="ion-margin-top"
                                        expand='block'
                                        shape='round'
                                        color='primary'
                                        type='submit'
                                        onClick={signInWithGoogle}
                                    >
                                        <IonIcon icon={logoGoogle} className='ion-padding-end' />{' '}
                                        Log in With Google
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            {/* <IonRow>
            <IonCol>
              <IonButton
                // className="ion-margin-top" 
                expand="block"
                shape="round"
                color="primary"
                type="submit" 
                onClick={signInWithMS}
              ><IonIcon icon={logoMicrosoft} className="ion-padding-end"/> Log in With Microsoft</IonButton>
            </IonCol>
          </IonRow> */}
                            <IonRow>
                                <IonCol>
                                    <IonButton
                                        // className="ion-margin-top"
                                        expand='block'
                                        shape='round'
                                        color='primary'
                                        type='submit'
                                        onClick={signInWithFB}
                                    >
                                        <IonIcon icon={logoFacebook} className='ion-padding-end' />{' '}
                                        Log in With Facebook
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <h2 className='ion-text-center headingText'>Or</h2>
                                    <IonButton
                                        // className="ion-margin-top"
                                        expand='block'
                                        shape='round'
                                        color='primary'
                                        fill='outline'
                                        type='submit'
                                        onClick={() => setEmailPassword(!emailPassword)}
                                    >
                                        Use Email & Password Instead
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    ) : (
                        <>
                            <IonRow>
                                <IonCol>
                                    <p className='ion-text-center'>
                                        <IonButton
                                            fill='clear'
                                            onClick={() => setEmailPassword(!emailPassword)}
                                        >
                                            Use One-Click Log in instead
                                        </IonButton>
                                    </p>
                                    <form onSubmit={(e) => signInWithEmailAndPasswordHandler(e)}>
                                        <IonList>
                                            <IonItem lines='full'>
                                                <IonLabel position='floating'>Email</IonLabel>
                                                <IonInput
                                                    value={email}
                                                    type='email'
                                                    inputMode='email'
                                                    // pattern="email"
                                                    onIonChange={(e) => setEmail(e.detail.value)}
                                                ></IonInput>
                                            </IonItem>
                                            <IonItem lines='full'>
                                                <IonLabel position='floating'>Password</IonLabel>
                                                <IonInput
                                                    value={password}
                                                    type='password'
                                                    onIonChange={(e) => setPassword(e.detail.value)}
                                                ></IonInput>
                                            </IonItem>
                                        </IonList>
                                        <div className='ion-margin-top ion-padding-vertical'>
                                            <IonButton
                                                // className="ion-margin-top"
                                                expand='block'
                                                shape='round'
                                                color='primary'
                                                type='submit'
                                                onClick={(event) => {
                                                    signInWithEmailAndPasswordHandler(event);
                                                }}
                                            >
                                                Log in
                                            </IonButton>
                                        </div>
                                    </form>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton
                                        routerLink='/register'
                                        color='primary'
                                        fill='clear'
                                        expand='block'
                                    >
                                        Register New Account
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton
                                        routerLink='/forgot'
                                        color='primary'
                                        fill='clear'
                                        expand='block'
                                    >
                                        Forgot Password
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    <IonRow>
                        <IonCol>
                            {error !== '' ? (
                                <IonText color='danger'>
                                    <p className='ion-padding-start'>{error}</p>
                                </IonText>
                            ) : (
                                <IonRow>
                                    <IonCol>
                                        <p className='ion-text-center'>
                                            <small>
                                                If you have previously created an account with email
                                                and password, or would prefer not to use a one-click
                                                log in, please choose this option.
                                            </small>
                                        </p>
                                    </IonCol>
                                </IonRow>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Login;
