import {
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from '../store/UserStore';
import { TownStore } from '../store/TownStore';
import { PermitStore } from '../store/PermitStore';
import firebaseApp from '../firebase';
import Onboarding from '../components/Onboarding/Onboarding';
import Start from '../components/Start';
import BurnBan from '../components/BurnBan';
import Widgets from '../components/Widgets';
// import headerIcon from '../assets/images/headerIcon.png';

const Account = (props) => {
    const firestore = firebaseApp.firestore();
    const userDoc = UserStore.useState((s) => s.userDoc);
    const riskLevel = TownStore.useState((s) => s.riskLevel);
    const [onboardingFlow, setOnboardingFlow] = useState(false);
    const municipality = props.match.params.id;

    useEffect(() => {
        if (municipality) {
            const getTownDoc = () => {
                // console.log(user)
                firestore
                    .collection('municipality')
                    .doc(municipality)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            const id = doc.id;
                            const terms = data.terms;
                            const agree = data.agree;
                            const riskLevel = data.riskLevel;
                            const burnBan = data.fireban;
                            console.log('data ', data);
                            TownStore.update((s) => {
                                s.townDoc = { id, ...data };
                                s.riskLevel = riskLevel;
                                s.burnBan = burnBan;
                            });
                            PermitStore.update((s) => {
                                s.terms = terms;
                                s.agree = agree;
                            });
                        } else {
                            TownStore.update((s) => {
                                s.townDoc = null;
                            });
                        }
                    })
                    .catch((error) => console.log(error));
            };
            const getPermitTypes = () => {
                // console.log(user)
                firestore
                    .collection('municipality')
                    .doc(municipality)
                    .collection('permitTypes')
                    .get()
                    .then((querySnapshot) => {
                        const types = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();
                            console.log(doc.id, ' => ', doc.data());
                            types.push({ id, ...data });
                        });
                        PermitStore.update((s) => {
                            s.permitTypes = types;
                        });
                    })
                    .catch((error) => console.log(error));
            };
            const getAnnouncements = () => {
                firestore
                    .collection('municipality')
                    .doc(municipality)
                    .collection('announcements')
                    .limit(3)
                    .get()
                    .then((querySnapshot) => {
                        const announcementsList = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();
                            // doc.data() is never undefined for query doc snapshots
                            announcementsList.push({ id, ...data });
                            // console.log(doc.id, " => ", doc.data());
                        });
                        const annoucementsSorted = announcementsList.sort((a, b) =>
                            a.dateCreated > b.dateCreated
                                ? -1
                                : b.dateCreated > a.dateCreated
                                ? 1
                                : 0
                        );
                        TownStore.update((s) => {
                            s.announcements = annoucementsSorted;
                        });
                    });
            };
            getTownDoc();
            getPermitTypes();
            getAnnouncements();
        }
    }, [municipality, firestore]);

    useEffect(() => {
        // console.log(userDoc);
        if (userDoc && !userDoc.complete) {
            setOnboardingFlow(true);
        }
    }, [userDoc]);

    return (
        <IonPage mode='ios'>
            <IonHeader className='whiteBar' color='primary'>
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                        {/* <img src={headerIcon} className='headerIcon' alt='PetLicense.Online' /> */}
                    </IonButtons>
                    <IonTitle>Account</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader color='primary' collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Account</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {onboardingFlow ? <Onboarding /> : riskLevel === 5 ? <BurnBan /> : <Start />}
                <IonGrid className='contentHolder'>
                    <Widgets />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Account;
