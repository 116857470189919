import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PetStore } from '../../../store/PetStore';

const Step1 = () => {
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const petDoc = PetStore.useState((s) => s.petDoc);

    const updateUserDoc = (e) => {
        PetStore.update((s) => {
            s.petDoc = { ...s.petDoc, name: e.target.value };
        });
    };
    return (
        <div className={step === 1 ? null : 'ion-hide'}>
            <h2 className='ion-padding-horizontal onboardingTitles'>What's your pet's name?</h2>
            <div style={{ height: '20px' }} />
            <IonItem lines='none'>
                <IonLabel className='onboardingLabels' position='stacked'>
                    Pet's Name
                </IonLabel>
                <IonInput
                    type='text'
                    autocapitalize='on'
                    placeholder='Enter name here'
                    className='onboardingInputs'
                    enterkeyhint='next'
                    inputmode='text'
                    pattern='text'
                    value={petDoc.name || ''}
                    onIonChange={(e) => updateUserDoc(e)}
                />
            </IonItem>
            <IonButton
                disabled={!petDoc.name ? true : false}
                className='ion-margin'
                shape='round'
                expand='block'
                onClick={() => {
                    PetStore.update((s) => {
                        s.petOnboardingStep = 2;
                    });
                }}
            >
                {'Continue'}
            </IonButton>
        </div>
    );
};

export default Step1;
