import {
    IonGrid,
    IonRow,
    IonCol,
    IonLoading,
    IonCard,
    IonIcon,
    IonCardHeader,
    IonCardTitle,
} from '@ionic/react';
import React, { useEffect } from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from '../store/UserStore';
import { PetStore } from '../store/PetStore';
import { PermitStore } from '../store/PermitStore';
import ApplicationModal from './Application/ApplicationModal';
import PetOnboardingModal from './PetOnboarding/PetOnboardingModal';
import { pawOutline, shieldCheckmarkOutline } from 'ionicons/icons';

const styles = {
    icon: {
        fontSize: 75,
    },
};

const Start = () => {
    const userDoc = UserStore.useState((s) => s.userDoc);
    const pets = PetStore.useState((s) => s.pets);

    const addPet = () => {
        PetStore.update((s) => {
            s.petOnboardingStep = 1;
        });
    };

    const getLicense = () => {
        PermitStore.update((s) => {
            s.applicationStep = 1;
        });
    };
    useEffect(() => {
        console.log(pets);
    }, [pets]);
    if (userDoc) {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <h2 className='headingText ion-text-center'>
                            Welcome, {userDoc.firstName}!
                        </h2>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs={12} sizeMd={6}>
                        <IonCard button onClick={addPet}>
                            <p style={{ textAlign: 'center' }}>
                                <IonIcon icon={pawOutline} style={styles.icon} />
                            </p>
                            <IonCardHeader>
                                <IonCardTitle className='ion-text-center'>
                                    Add {pets.length ? 'Another' : 'Your'} Pet
                                </IonCardTitle>
                            </IonCardHeader>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeXs={12} sizeMd={6}>
                        <IonCard disabled={!pets.length} button onClick={getLicense}>
                            <p style={{ textAlign: 'center' }}>
                                <IonIcon
                                    icon={shieldCheckmarkOutline}
                                    color={pets.length ? 'warning' : null}
                                    style={styles.icon}
                                />
                            </p>
                            <IonCardHeader>
                                <IonCardTitle className='ion-text-center'>
                                    Get a License
                                </IonCardTitle>
                            </IonCardHeader>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <PetOnboardingModal />
                <ApplicationModal />
            </IonGrid>
        );
    } else {
        return <IonLoading isOpen={true} />;
    }
};

export default Start;
