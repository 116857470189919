import { Store } from 'pullstate';

export const PermitStore = new Store({
    permits: [],
    applicationModal: false,
    applicationStep: 0,
    terms: null,
    permitTypes: [],
    initialApplicationState: {
        type: null,
        address: null,
        price: null,
        pet: null,
    },
    application: {
        type: null,
        address: null,
        price: null,
        pet: null,
    },
    activities: [],
});
