import React, { useState } from 'react';
import '../../Page.css';

import { firestore } from '../../../firebase';
/* Pullstate Store */
import { PetStore } from '../../../store/PetStore';
import { IonButton, IonLoading } from '@ionic/react';
import { toast } from '../../Toast/Toast';
import { UserStore } from '../../../store/UserStore';

const Step5 = () => {
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const petDoc = PetStore.useState((s) => s.petDoc);
    const user = UserStore.useState((s) => s.user);
    const [isLoading, setIsLoading] = useState(false);
    console.log(user);
    const savePet = () => {
        setIsLoading(true);
        firestore
            .collection('users')
            .doc(user.uid)
            .collection('pets')
            .add({
                ...petDoc,
            })
            .then(() => {
                toast(`Added ${petDoc.name} to your pets`, 'success');
                setIsLoading(false);
                PetStore.update((s) => {
                    s.petOnboardingStep = 6;
                });
            })
            .then(() => {
                const getUserPets = () => {
                    // console.log(user)
                    firestore
                        .collection('users')
                        .doc(user.uid)
                        .collection('pets')
                        .get()
                        .then((querySnapshot) => {
                            const petsList = [];
                            querySnapshot.forEach((doc) => {
                                const data = doc.data();
                                const id = doc.id;
                                petsList.push({ id, ...data });
                                // doc.data() is never undefined for query doc snapshots
                                console.log(doc.id, ' => ', doc.data());
                            });
                            console.log(petsList);
                            PetStore.update((s) => {
                                s.pets = petsList;
                            });
                        })
                        .catch((error) => console.log(error));
                };
                getUserPets();
            })
            .catch((error) => {
                toast('Update pet failed', error);
                setIsLoading(false);
            });
    };
    return (
        <>
            <div className={step === 5 ? 'steps' : 'ion-hide'}>
                <h2 className='ion-padding-horizontal onboardingTitles'>
                    Please confirm {petDoc.name}'s details:
                </h2>
                <div style={{ height: '20px' }} />
                <div className='ion-padding'>
                    <p className='ion-text-center deets'>
                        {petDoc.name}, the {petDoc.species.toLowerCase()}, is...
                    </p>
                    <p className='ion-text-center deets'>{petDoc.age} years old.</p>
                    {petDoc.breed ? (
                        <p className='ion-text-center deets'>
                            {petDoc.gender === 'male' ? 'His' : 'Her'} breed is {petDoc.breed}.
                        </p>
                    ) : null}
                    <p className='ion-text-center deets'>
                        {petDoc.gender === 'male' ? 'He' : 'She'}{' '}
                        {petDoc.neutered ? 'is' : 'is not'} spayed/neutered.
                    </p>
                    <p className='ion-text-center deets'>
                        {petDoc.gender === 'male' ? 'His' : 'Her'} immunisation{' '}
                        {petDoc.immunisation ? 'is' : 'is not'} up-to-date.
                    </p>
                    <p className='ion-text-center deets'>
                        And, {petDoc.gender === 'male' ? 'his' : 'her'} veteriarian is located at:
                        <br />
                        <br />
                        <small style={{ marginTop: 45 }}>{petDoc.vetAddress}.</small>
                    </p>
                </div>
                <IonButton
                    className='ion-margin'
                    shape='round'
                    expand='block'
                    onClick={() => savePet()}
                >
                    {`Save ${petDoc.name}'s Profile!`}
                </IonButton>
            </div>
            <IonLoading isOpen={isLoading} />
        </>
    );
};

export default Step5;
