import React from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from '../store/UserStore';
import { PermitStore } from '../store/PermitStore';
import { TownStore } from '../store/TownStore';
import pbg from '../assets/images/pbg.png';
import { IonLabel } from '@ionic/react';
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';
import { PetStore } from '../store/PetStore';

const AddressPicker = (props) => {
    const { context } = props;
    const townDoc = TownStore.useState((s) => s.townDoc);
    // console.log(context)
    const getCoords = (address) => {
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                if (context === 'application') {
                    PermitStore.update((s) => {
                        s.application = { ...s.application, latitude: lat, longitude: lng };
                    });
                }
                if (context === 'onboarding') {
                    UserStore.update((s) => {
                        s.userDoc = { ...s.userDoc, homeLatitude: lat, homeLongitude: lng };
                    });
                }
            });
    };
    const setAddress = (fullAddress) => {
        // console.log('setting');
        if (context === 'application') {
            // console.log('should update application');
            PermitStore.update((s) => {
                s.application = { ...s.application, otherAddress: fullAddress };
            });
        }
        if (context === 'vet') {
            // console.log('should update application');
            PetStore.update((s) => {
                s.petDoc = { ...s.petDoc, vetAddress: fullAddress };
            });
        }
        if (context === 'onboarding') {
            // console.log('should update userdoc');
            UserStore.update((s) => {
                s.userDoc = { ...s.userDoc, address: fullAddress, fullAddress: fullAddress };
            });
        }
    };
    return (
        <div className='ion-padding-horizontal'>
            {context !== 'vet' ? (
                <IonLabel className='onboardingLabels' position='stacked'>
                    Enter Address
                </IonLabel>
            ) : null}
            <div style={{ height: '16px' }} />
            <GooglePlacesAutocomplete
                autocompletionRequest={{
                    componentRestrictions: {
                        country: [townDoc.countryCode],
                    },
                    strictBounds: true,
                }}
                apiKey='AIzaSyCnh3jo3JObQ7fEv8TmJH8TJFJo26tlsdY'
                selectProps={{
                    onChange: (e) => {
                        var fullAddress =
                            e.value.structured_formatting.main_text +
                            ', ' +
                            e.value.structured_formatting.secondary_text;
                        getCoords(fullAddress);
                        setAddress(fullAddress);
                    },
                }}
                inputClassName='addressBox'
                placeholder={'Type the address'}
                initialValue={''}
                suggestionsClassNames={{
                    container: 'suggestionContainer',
                    suggestion: 'suggestionItem',
                    suggestionActive: 'suggestionActive',
                }}
            />
            <p className='ion-text-center'>
                <img src={pbg} alt='Powered by Google' />
            </p>
            <p className='ion-text-left'>
                Use the search box above by entering the address you're looking for. When you see
                the correct address, please click/tap on it to set it.
            </p>
        </div>
    );
};

export default AddressPicker;
