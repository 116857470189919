import React from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PermitStore } from '../../../store/PermitStore';
import {
    IonBadge,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { PetStore } from '../../../store/PetStore';
import CheckoutForm from '../../CheckoutForm/CheckoutForm';
import dayjs from 'dayjs';
import pbs from '../../../assets/images/pbs.png';

const Step1 = () => {
    const step = PermitStore.useState((s) => s.applicationStep);
    const application = PermitStore.useState((s) => s.application);
    const permitTypes = PermitStore.useState((s) => s.permitTypes);
    const pets = PetStore.useState((s) => s.pets);

    const selectPet = (val) => {
        PermitStore.update((s) => {
            s.application = {
                ...application,
                pet: val,
            };
        });
    };

    const handleChange = (e) => {
        const { name, paid, price, validFor } = e.detail.value;
        console.log(e.detail.value);
        if (validFor === 'EOY') {
            // console.log(dayjs(Date.now()).format('YYYY') + '-12-31');
            PermitStore.update((s) => {
                s.application = {
                    ...s.application,
                    type: name,
                    paid,
                    price,
                    validFor,
                    to: dayjs(Date.now()).format('YYYY') + '-12-31',
                    endDate: dayjs(Date.now()).format('YYYY') + '-12-31',
                };
            });
        } else {
            PermitStore.update((s) => {
                s.application = {
                    ...s.application,
                    type: name,
                    paid,
                    price,
                    validFor,
                    to: Date.now() + validFor,
                    endDate: Date.now() + validFor,
                };
            });
        }
    };

    return (
        <div className={step === 1 ? null : 'ion-hide'}>
            <h2 className='ion-padding-horizontal onboardingTitles'>
                Please complete the following form:
            </h2>
            <div style={{ height: 20 }} />
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                Pet
                            </IonLabel>
                            <IonSelect
                                value={application.pet}
                                placeholder='Select One'
                                onIonChange={(e) => selectPet(e.target.value)}
                            >
                                {pets &&
                                    pets.map((pet, i) => (
                                        <IonSelectOption value={pet} key={i}>
                                            {pet.name}
                                        </IonSelectOption>
                                    ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                License Type
                            </IonLabel>
                            <IonRadioGroup
                                mode='md'
                                aria-label={'List of burn permits'}
                                checked={application.type}
                                onIonChange={(e) => {
                                    handleChange(e);
                                }}
                            >
                                {permitTypes &&
                                    permitTypes.map((permit, i) => (
                                        <div key={i}>
                                            <IonItem aria-label={'Burn permit type'} lines='none'>
                                                <IonLabel className='ion-text-wrap'>
                                                    {permit.id} {permit.desc ? '-' : ''}{' '}
                                                    {permit.desc}
                                                </IonLabel>
                                                <IonRadio slot='start' value={permit} />
                                                <IonBadge slot='end'>${permit.price}</IonBadge>
                                            </IonItem>
                                        </div>
                                    ))}
                            </IonRadioGroup>
                        </IonItem>
                    </IonCol>
                </IonRow>
                {application.pet && application.type ? (
                    <IonRow>
                        <IonCol>
                            <IonItem lines='none'>
                                <IonLabel className='onboardingLabels' position='stacked'>
                                    Payment
                                </IonLabel>
                                <CheckoutForm />
                                <div className='ion-padding'>
                                    <p className='ion-text-center'>
                                        <img
                                            style={{ height: '40px', width: 'auto' }}
                                            src={pbs}
                                            alt='Powered by Stripe'
                                        />
                                    </p>
                                    <p className='ion-text-center'>
                                        Secure payments powered by Stripe. All major credit/debit
                                        cards accepted.
                                    </p>
                                </div>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                ) : null}
            </IonGrid>
        </div>
    );
};

export default Step1;
